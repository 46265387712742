<template>
  <div
    class="container flex-wrap gap-5 py-3 py-lg-5 d-flex justify-content-center w-100"
  >
    <template
      v-if="data.icons"
    >
      <div
        v-for="icon in data.icons"
        :key="icon._key"
        :class="data.largeIcons ? 'large-icon-container' : 'small-icon-container'"
        class="d-flex flex-column align-items-center"
      >
        <img
          v-if="icon.icon?.asset?.url"
          class="mb-2 img-fluid"
          :class="data.largeIcons ? 'large-icon' : 'small-icon'"
          :src="icon.icon.asset.url"
          :alt="icon.title"
        />
        <span
          class="text-center fs-body fw-bold text-main"
          :style="{ color: icon?.iconTitleColor?.hex + '!important' }"
        >{{ icon.title }}</span
        >
        <span
          class="text-center fs-6 text-main"
          :style="{ color: icon?.iconTextColor?.hex + '!important' }"
        >{{ icon.text }}</span
        >
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "JobIcons",

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
.small-icon-container {
  width: 16rem;
}

.large-icon-container {
  width: 20rem;
}

@media (max-width: 1024px) {
  .small-icon {
    width: 4rem !important;
  }

  .large-icon {
    width: 6rem !important;
  }
}

.small-icon {
  width: 5rem;
}

.large-icon {
  width: 8rem;
}

@media (max-width: 1024px) {
  .fs-body {
    font-size: 1.25rem !important;
  }
}

.fs-body {
  font-size: 1.5rem;
}
</style>
